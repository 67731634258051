import _objectSpread from "/Volumes/workspace/project/twiboss/frontend/twi-super-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getUserWithdrawList, auditUserWithdraw } from '@/api/c_user';
export default {
  name: "channelWithdraw",
  data: function data() {
    return {
      data: [],
      loading: false,
      queryParams: {
        user_id: null,
        app_id: null,
        status: 4,
        page: 1,
        page_size: 20
      },
      total: 0,
      statusOptions: [{
        label: "全部",
        value: -1
      }, {
        label: "上游处理中",
        value: 0
      }, {
        label: "提现成功",
        value: 1
      }, {
        label: "提现失败",
        value: 2
      }, {
        label: "待客户审核",
        value: 3
      }, {
        label: "待财务审核",
        value: 4
      }],
      dialogVisible: false,
      reReson: {
        id: '',
        remark: '',
        audit_result: ''
      }
    };
  },
  filters: {
    handleRemark: function handleRemark(val) {
      if (val && val.length > 10) {
        var tips = val.substring(0, 10);
        return tips + '...';
      } else {
        return val;
      }
    }
  },
  created: function created() {
    this.requestData();
  },
  methods: {
    getStatusText: function getStatusText(status) {
      var item = this.statusOptions.find(function (e) {
        return e.value === status;
      });
      return item ? item.label : '-';
    },
    requestData: function requestData(action) {
      var _this = this;

      if (action === 0 && this.queryParams.page !== 1) {
        this.queryParams.page = 1;
      }

      this.loading = true;
      getUserWithdrawList(_objectSpread({}, this.queryParams)).then(function (res) {
        if (res.code === 0) {
          _this.loading = false;
          _this.data = res.data.list;
          _this.total = res.data.total;
        }
      });
    },
    handleAudit: function handleAudit(row, audit) {
      var _this2 = this;

      if (audit === 2) {
        if (row.is_age_over || row.this_month_tx_amount > 100000) {
          return this.$message.warning('年龄不符或当月提现额度超过10万元');
        }
      }

      var type = audit === 1 ? '通过' : audit === 2 ? '自动打款' : '拒绝';
      this.$confirm('您确定要处理这项数据吗?', type, {
        type: 'warning'
      }).then(function () {
        if (audit === 3) {
          _this2.dialogVisible = true;
          _this2.reReson = {
            id: row.id,
            remark: '',
            audit_result: audit
          };
        } else {
          auditUserWithdraw({
            id: row.id,
            audit_result: audit
          }).then(function (res) {
            if (res.code === 0) {
              _this2.$message.success('处理成功');

              _this2.requestData();
            }
          });
        }
      }).catch(function () {
        _this2.$message.info('已取消处理');
      });
    },
    handleClose: function handleClose() {
      this.reReson = {
        id: '',
        remark: '',
        audit_result: ''
      };
      this.dialogVisible = false;
    },
    refuseRefund: function refuseRefund() {
      var _this3 = this;

      auditUserWithdraw(_objectSpread({}, this.reReson)).then(function (res) {
        if (res.code === 0) {
          _this3.$message.success('处理成功');

          _this3.dialogVisible = false;
          _this3.reReson = {
            id: '',
            remark: '',
            audit_result: ''
          };

          _this3.requestData();
        }
      });
    }
  }
};