// C端用户
import service from '@/utils/request';
/**
 * C端提现列表
 * @param {Object} params { user_id, status: 0-上游处理中；1-提现成功；2-提现失败；3-待客户审核；4-待财务审核 }
 */

export var getUserWithdrawList = function getUserWithdrawList(params) {
  return service({
    url: "/finance_with_withdraw/list",
    method: 'get',
    params: params
  });
};
/**
 * 审核 C端提现
 * @param {Object} data { id: 提现申请ID, audit_result: 1-通过，2-自动打款，3-不通过 }
 */

export var auditUserWithdraw = function auditUserWithdraw(data) {
  return service({
    url: "/finance_with_withdraw/passe",
    method: 'post',
    data: data
  });
};